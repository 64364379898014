import firebase from "gatsby-plugin-firebase";

import HomeAppBar from "../components/HomeAppBar";


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import React from "react";
import { Button } from "@material-ui/core";




export default class SeasonsPage extends React.Component {

    state = {
        seasonList: [],
        newSeasonFields :[
            {
                name:"seasonName",
                title:"Season Name",
                required:true,
                type:"text"
            },
            {
                name:"seasonStartDate",
                title:"Start Date",
                required:true,
                type:"date"
            },
            {
                name:"seasonEndDate",
                title:"End Date",
                required:true,
                type:"date"
            },
            {
                name:"seasonTime",
                title:"Season Time(Fall,Spring,etc)",
                required:true,
                type:"select",
                options: ["Winter","Spring","Summer","Fall"]
            }
            
        ],
        newSeason: {
            isActive: true,
            year: new Date().getFullYear(),
            seasonTime:"Winter"
        }
    }

    createNewSeasonDisabled = () =>{
        for (const field of this.state.newSeasonFields) {
            if(field["required"]){
                switch (field["type"]) {
                    case "text":
                        try{
                            if(this.state.newSeason[field["name"]].length < 1){
                            return true
                        }
                        }
                        catch(err){
                            return true
                        }
                        
                        break;
                    case "date":
                        try{
                            var date = new Date(this.state.newSeason[field["name"]])
                            if(date instanceof Date && !isNaN(date.valueOf())){

                            }
                            else{
                                return true
                            }
                        }
                        catch(err){
                            return true
                        }
                        break;
                    default:

                        break;
                }
            }
        }
        return false
    }

    newSeasonTableRow = (props) =>{
        return (
            <TableRow>
                <TableCell>{props.title}</TableCell>
                <TableCell>{
                    props.type == "select" ?
                    <select defaultValue="Winter" onChange={(ev) =>{
                        console.log(ev.target.value)
                        this.setState({newSeason: {...this.state.newSeason,[props.name]: ev.target.value }}, ()=>{console.log(this.state)})}}>{props.options.map((data)=>{
                        return (
                            <option value={data}>{data}</option>
                        )
                    })}</select>
                     :<input type={props.type} onChange={(event) =>{
                    this.setState({newSeason: {...this.state.newSeason, [props.name]: event.target.value}},
                        () => {console.log(this.state)})
                }} />
            
                    }
                    
                </TableCell>
            </TableRow>
        )
    }

    componentDidMount() {
        firebase.database()
            .ref("seasons").equalTo(1, "isActive").on("value", (data) => {
                console.log(data.val())
            })
    }

    createNewSeason =() => {
        return (
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Field</TableCell>
                        <TableCell>Value</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        this.state.newSeasonFields.map((data,index)=>{
                            return (
                                <this.newSeasonTableRow {...data}></this.newSeasonTableRow>
                            )
                        })
                    }
                    <TableRow>
                <TableCell></TableCell>
                <TableCell>
                    <Button disabled={this.createNewSeasonDisabled()}
                    onClick={()=>{

                        firebase.database().ref("seasons").push({...this.state.newSeason})
                    }}
                    >Save</Button>
                    </TableCell>
            </TableRow>
                </TableBody>
            </Table>
        )
    }

 


    render() {
       
        return (

            <div>
                <HomeAppBar title="Create New Season" />
                
               <this.createNewSeason></this.createNewSeason> 
            </div>
            
        )
    }
}